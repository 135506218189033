<template>
    <div class="post__edit-wrap">
        <div class="post__edit-content">
            <input
                v-if="isAdvert"
                v-model="post.title"
                class="post__edit-title"
                placeholder="Тема объявления"
                type="text"
            />
            <textarea-autosize
                v-model="post.content"
                class="post__edit-text"
                placeholder="Сообщение"
            />

            <ul v-if="post.images.length" class="post__edit-images">
                <li
                    v-for="image in post.images"
                    :style="{ backgroundImage: `url(${image.src || image.file})` }"
                    class="post__edit-image"
                >
                    <i
                        class="post__edit-image-delete"
                        @click="deleteImg(image.id)"
                    />
                </li>
            </ul>
        </div>

        <!-- Проверка :disabled для того, чтобы проверялась заполненность полей в зависимости от типа поста -->
        <div class="post__edit-footer">
            <div class="post__edit-footer__row">
                <div class="post__edit-footer__col">
                    <div class="post__edit-btns">
                        <button
                            class="v-btn v-btn--transparent post__cancel-btn"
                            @click="onCancel"
                        >
                            Отменить
                        </button>

                        <button
                            class="v-btn post__edit-save"
                            :disabled="btnValidate"
                            @click="() => onSave(post)"
                        >
                            Сохранить
                        </button>
                    </div>
                </div>

                <div class="post__edit-footer__col">
                    <label
                        :for="`id_attach-img-${postId}`"
                        title="Изображение"
                        class="new-post__attach-item new-post__attach-item--img"
                    >
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="new-post__img-icon"
                        >
                            <rect width="18" height="18" rx="2" fill="#DBDEE9"/>
                            <circle cx="4.5" cy="4.5" r="1.5" fill="white"/>
                            <path
                                d="M5.5 10L2.29289 13.2071C2.10536 13.3946 2 13.649 2 13.9142V15C2 15.5523 2.44772 16 3 16H15C15.5523 16 16 15.5523 16 15V4.5L9 12L5.5 10Z"
                                fill="white"
                            />
                        </svg>

                        <input
                            type="file"
                            ref="preview"
                            name="attach-img"
                            hidden
                            :id="`id_attach-img-${postId}`"
                            @change="addImgHandler"
                        >
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import session from '@/api/session';

    export default {
        name: "EditPost",
        props: {
            data: {
                type: Object,
                required: true
            },
            isAdvert: {
                type: Boolean,
                required: false,
                default: false
            },
            onSave: {
                type: Function,
                required: true
            },
            onCancel: {
                type: Function,
                required: true
            }
        },
        data() {
            return {
                postId: this._uid,
                post: { ...this.data }
            }
        },
        computed: {
            ...mapState('default_data', [
                'limits',
                'domain'
            ]),

            btnValidate() {
                const titleLength = this.post.title.length;
                const textLength = this.post.content.length;
                const { MIN_CHARS } = this.limits;

                if (this.isAdvert) {
                    return titleLength < MIN_CHARS || textLength < MIN_CHARS
                } else {
                    return textLength < MIN_CHARS
                }
            }
        },

        methods: {
            deleteImg(id) {
                this.post.images = this.post.images.filter(post => id !== post.id);
            },

            async addImgHandler(e) {
                const file = e.target.files[0];
                const ALLOWED_TYPES = /\.(jpe?g|png)$/i;
                const idImage = this.post.images.length ? (this.post.images[this.post.images.length - 1].id + 1) : 1;

                if (!ALLOWED_TYPES.test(file.name)) {
                    this.toastError('Допустимые форматы: png, jpg, jpeg');
                    return false;
                } else if (file.size > 5000000) {
                    this.toastError('Максимальный размер изображения: 5мб');
                    return false;
                } else if (this.post.images.length > 10) {
                    this.toastError('Максимум изображений: 10');
                    return false;
                } else {
                    const dataFile = {
                        file_name: file.name,
                        domain_name: this.domain.subdomain
                    };

                    await session.post('/api/v1/s3/', dataFile)
                        .then(async (request) => {
                            const data = request.data;
                            const dataImg = {
                                id: idImage,
                                name: file.name,
                                file: data['public-link'],
                                author: null,
                                created_at: this.$moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
                                wall: this.post.id
                            }

                            await session.put(`${data['upload-link']}`, file);
                            this.post.images.push(dataImg);
                        })
                        .catch(() => {
                            this.$swal({
                                title: 'Ошибка!',
                                text: 'При загрузки изображения произошла ошибка. Попробуйте ещё раз.',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 2400
                            });
                        });
                }

                // Очистка значения у поля для того, чтобы можно было загружать один и тот же файл подряд
                e.target.value = null;
            },

            toastError(text, timer = 3000) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    title: text,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: timer,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    }
                });
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import '#sass/_variables.sass'
    @import '#sass/v-style'

    .post__edit
        padding: 5px 10px
        width: 100%
        border: 1px solid rgba(0,0,0, .2)
        resize: none
        outline: none
        margin-bottom: 10px
        &-text,
        &-title
            width: 100%
            border: 1px solid rgba(0,0,0, .2)
            outline: none
            padding: 10px 15px
        &-title
            border-bottom: none
            font-weight: 600
        &-content
            display: flex
            flex-direction: column
        &-images
            display: flex
            width: 100%
            margin-top: 25px
            overflow-x: auto
        &-image
            height: 80px
            width: 80px
            min-height: 80px
            min-width: 80px
            background-color: $black-2
            background-repeat: no-repeat
            background-size: cover
            position: relative
            &:nth-child(n+2)
                margin-left: 15px
            &-delete
                height: 16px
                width: 16px
                position: absolute
                top: 0
                right: 0
                background-color: rgba(0, 0, 0, .7)
                background-image: $times-icon
                background-repeat: no-repeat
                background-position: center
                z-index: 2
                cursor: pointer
        &-btns
            display: flex
        &-footer
            margin-top: 20px
            &__row
                margin: -5px
                display: flex
                flex-wrap: wrap
                align-items: center
                justify-content: space-between
            &__col
                margin: 5px
                flex-grow: 1
                display: flex
                align-items: center
                &:last-of-type
                    justify-content: flex-end
</style>
